.Gif {
    display: block;
    padding: 25px 0;
}
.Gif img {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
  
}

@media(max-width:768px) {
    .Gif {
        padding: 10px 0;
    }
}