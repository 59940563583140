/* Base styles */
.Bio {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding-bottom: var(--main-padding-bottom);
    padding-top: var(--main-padding-top);
}

.Bio .image {
    max-width: 500px;
}

.Bio .image img {
    object-fit: cover;
    width: 100%;
    height: auto;
}

.Bio .info .name h2 {
    margin-bottom: 0;
}

.Bio .info .name p {
    color: var(--text-color);
}

.Bio .info .aboutMe {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    text-align: justify;
}

.Bio .info .aboutMe p {
    color: #333;
    line-height: 2;
    padding: 5px 0;
}

.Bio .info .contact {
    display: block;
    text-align: start;
    text-transform: capitalize;
}

/* Styles for specific icons */
.Bio .info .icon {
    display: flex;
    margin-bottom: 10px;
}

.Bio .info .icon span {
    font-size: 30px;
    margin-right: 15px;
    color: #60B4AA;
}
.Bio .info .icon a {
    font-size: 20px;
    color: #333;
    margin: auto 0;
}
.Bio .info .icon a:hover {
        color: #60B4AA;
}

/* Responsive styles */
@media (max-width: 768px) {
    .Bio {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .Bio .info .name p {
        font-size: 13px;
    }
    .Bio .image {
        max-width: 300px;
        margin: auto;
    }
    .Bio .image img {
        width: 100%;
    }
    .Bio .info .aboutMe {
        font-size: 14px;
    }
    .Bio .info .icon a {
        font-size: 15px;
    }
}

@media (max-width: 576px) {
    .Bio .info .aboutMe {
        font-size: 10px;
   word-wrap: break-word;
    }
        .Bio .info .icon a {
        font-size: 12px;
    }
}
