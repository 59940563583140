.image2 {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.image2 img {
    width: 100%;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    pointer-events: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}
/* .image2 a {
    transition:  0.3s ;
    -webkit-transition:  0.3s ;
    -moz-transition:  0.3s ;
    -ms-transition:  0.3s ;
    -o-transition:  0.3s ;
    
}
.image2 a:hover {
   
     filter: brightness(0.7);
     -webkit-filter: brightness(0.7);
     -moz-border-filter: brightness(0.7);
     -ms-border-filter: brightness(0.7);
     -o-border-filter: brightness(0.7);
     transform: scale(1.04) ;
     -webkit-transform: scale(1.04) ;
     -moz-transform: scale(1.04) ;
     -ms-transform: scale(1.04) ;
     -o-transform: scale(1.04) ;
     
} */