/* .image { */

/* display: flex;
  flex-direction: column; */

/* overflow: hidden; */

/* } */

.img-card {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  perspective: 1px;
  background-color: red;
}

.img-card img {
  width: 100%;
  pointer-events: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.img-card a {
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.img-card a:hover {
  filter: brightness(0.7);
  -webkit-filter: brightness(0.7);
  -moz-border-filter: brightness(0.7);
  -ms-border-filter: brightness(0.7);
  -o-border-filter: brightness(0.7);
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -ms-transform: scale(1.04);
  -o-transform: scale(1.04);
}

.image span {
  padding: 5px 0;
  color: #aaa;
  font-size: 20px;
}

.image h2 {
  display: block;
  width: fit-content;
  position: relative;
}

.image h2::after {
  content: '';
  position: absolute;
  background-color: black;
  width: 0;
  height: 2px;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  bottom: -10px;
  left: 0;
}

.image:hover h2::after {
  width: 100%;
}
