
.images {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    padding: 25px 0;
}


.image span {
    display: block;
}
@media(max-width:768px ) {
    .images {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 20px;
    padding: 25px 0;
}
}

.home .page .up-down {
    display: block;
    font-size: 40px;
    text-align: center;
    margin-bottom: 15px;
    transition: var(--main-transition);
    -webkit-transition: var(--main-transition);
    -moz-transition: var(--main-transition);
    -ms-transition: var(--main-transition);
    -o-transition: var(--main-transition);
    color: #aaa;
}
.home .page .up-down:hover {
    color: #60B4AA;
    transform: translateY(-10px) ;
    -webkit-transform: translateY(-10px) ;
    -moz-transform: translateY(-10px) ;
    -ms-transform: translateY(-10px) ;
    -o-transform: translateY(-10px) ;
}
