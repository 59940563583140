
/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300&family=Open+Sans:wght@400;700;800&family=Poppins:wght@200;300;400;500;600;700;800&family=Work+Sans:wght@200;300;400;500;600;700;800&display=swap');

/*  global CSS styles  */

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --main-color : white;
  --Second-color:Black;
  --text-color:#aaa;
  --main-transition: 0.3s;
  --logo-color : #60B4AA;
  --main-padding-top: 50px;
  --main-padding-bottom: 50px;
}
html {
  scroll-behavior: smooth;
}
body {
font-family: 'Cairo', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Work Sans', sans-serif;

}


ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.disable-scroll {
  overflow: hidden;
}
