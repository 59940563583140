/* //zaiad */
.youtube {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}