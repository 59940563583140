.navbar {
    position: fixed;
    background-color: #333333;
    z-index: 99999;
    /* min-height: 30vh; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.navbar ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    text-align: center;
    
}
.navbar ul li a {
    display: inline-block;
     padding: 20px 10px;
     position: relative;
     transition: transform 0.3s;
     -webkit-transition: transform 0.3s;
     -moz-transition: transform 0.3s;
     -ms-transition: transform 0.3s;
     -o-transition: transform 0.3s;
     margin: 0;
    
}
.navbar ul li a:hover {
 transform: translateX(15px);
 -webkit-transform: translateX(15px);
 -moz-transform: translateX(15px);
 -ms-transform: translateX(15px);
 -o-transform: translateX(15px);
}
.navbar ul li a::after {
   content: "";
   position: absolute;
   width: 0;
   bottom: 25px;
   background-color: #f5f5f5;
   height: 2px;
   left: 0;
   transition: var(--main-transition);
   -webkit-transition: var(--main-transition);
   -moz-transition: var(--main-transition);
   -ms-transition: var(--main-transition);
   -o-transition: var(--main-transition);
}
.navbar ul li a:hover::after {
    width: 100%;

}
.navbar ul li a:first-child {
    font-size: 29px;
}
.navbar ul li a {
    cursor: pointer;
    color: #f5f5f5;
    font-weight: 400;
        font-style: normal;
        font-size: 36px;
        line-height: 1.6em;
        text-transform: none;
}
@media(max-width:576px) {
        .navbar ul li a:first-child {
    font-size: 20px;
}
.navbar ul li a {
    padding: 40px 5px;
}
}
@media(min-width:576px) {
    .navbar ul li a:first-child {
    font-size: 24px;
}
.navbar ul li a {
    padding: 40px 5px;
}
}