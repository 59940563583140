.footer {
    background-color: var(--Second-color);
    color: white;
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 20px;
    padding: 25px;
    text-transform: capitalize;
    position: relative;
} 

@media (max-width:768px) {
    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
           grid-template-columns: 1fr;
    }
    .footer .info {
       display: block;
      text-align: center;
    }

    .footer nav ul li {
      font-size: 10px;
       display: block;
      text-align: center;
      margin: 0 auto;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .footer .info {
        display: block;
        text-align: center;
    }

    .footer nav ul li {
        font-size: 10px;
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    .footer {
        grid-template-columns: 1fr;
    }
}

.footer .info .info_name {
    margin-bottom: 25px;
}

.footer .info .info_name h2 {
    margin: 0;
    letter-spacing: 1.2px;
}

.footer .info .info_name span {
    color: var(--text-color);
}

.footer  nav ul li {
    padding: 10px 5px;
    letter-spacing: 0.9px;
    position: relative;
    width: fit-content;
}
.footer nav ul li a{
    color: #aaa;
    
}
.footer nav ul li a:hover {
    color: white;
}
.footer nav ul li a::after{
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: var(--main-color);
    left: 0;
    bottom: 0;
    transition:  var(--main-transition);
    -webkit-transition:  var(--main-transition);
    -moz-transition:  var(--main-transition);
    -ms-transition:  var(--main-transition);
    -o-transition:  var(--main-transition);
}
.footer nav ul li a:hover::after {
    width: 100%;
    right: 0;
    
}

.footer .social h2{
    font-size: 40px;
}

.footer .icons {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    
}

.footer .icons .icon {
    padding: 10px 40px;
    font-size: 50px;
    margin-right: 5px;
    cursor: pointer;
    color: #aaa;
}
.footer .icons .icon:hover {
    color: var(--main-color);
}
.footer .ahmad  {
    text-align: 'center' 
}